@import "~antd/dist/antd.less";

p {
  margin-bottom: 0;
}
.ant-layout-header {
  height: 66px;
}

.desktop-header {
  .ant-menu-item,
  .ant-menu-submenu-title {
    font-size: 16px;
  }
}

.footer-links {
  a {
    color: #fff;
  }
}

.item-explanation,
.question-desription,
.mega-question-description {
  p {
    margin-bottom: 10px;
  }

  table {
    font-style: normal !important;
    font-size: 13px;
    margin-bottom: 20px;

    p {
      margin-bottom: 6px;
    }
  }

  td,
  th {
    border: 1px solid #999;
    padding: 0.8rem;
    text-align: left;
  }
}

